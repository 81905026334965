import React from "react";
import Pagelayout from "../layouts/page";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Col, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faAt,
  faEnvelope,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover,
  :active,
  .active,
  .focus,
  :focus,
  :not(:disabled):not(.disabled).active,
  :not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus,
  :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`;

const Googlemap = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
`;

const Contactrow = styled(Row)`
  margin-bottom: 20px;
  text-align: center;

  & svg {
    color: ${({ theme }) => theme.colours.primary.main};
  }
`;

const Contactbox = styled(Col)`
  // background: white;
  // padding: 15px 0;
  // box-shadow: ${({ theme }) => theme.shadows.shadow1};
  margin-bottom: 25px;

  @media (min-width: 576px) {
    font-size: 0.7em;
    margin-bottom: inherit;
  }
`;

function ContactPage({ data }) {
  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6Lf992AeAAAAAK-JN5F3GBhI3_hHy1kJ3sT4qVEp"></script>
      </Helmet>
      <Seo
        title={`${data.directus.settings.site_title} · ${data.directus.home.seo_title}`}
        description={data.directus.settings.site_description}
        author={data.directus.settings.site_author}
      />
      <Pagelayout
        logo={data.directus.settings.site_logo}
        footerimage={data.directus.settings.footer_image}
      >
        <Row>
          <Col>
            <Pageheading>Contact Us</Pageheading>
          </Col>
        </Row>
        <Contactrow>
          <Col>
            <div
              dangerouslySetInnerHTML={{
                __html: data.directus.contact.contact_details,
              }}
            />
          </Col>
        </Contactrow>
        <Contactrow>
          <Contactbox xs={12} md={6} lg={3}>
            <h3>
              <FontAwesomeIcon icon={faHome} /> Address
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.directus.contact.address,
              }}
            />
          </Contactbox>
          <Contactbox xs={12} md={6} lg={3}>
            <h3>
              <FontAwesomeIcon icon={faAt} /> E-Mail
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.directus.contact.email,
              }}
            />
          </Contactbox>
          <Contactbox xs={12} md={6} lg={3}>
            <h3>
              <FontAwesomeIcon icon={faPhone} /> Phone
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.directus.contact.phone,
              }}
            />
          </Contactbox>
          <Contactbox xs={12} md={6} lg={3}>
            <h3>
              <FontAwesomeIcon icon={faEnvelope} /> Mailing
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.directus.contact.mailing,
              }}
            />
          </Contactbox>
        </Contactrow>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <form method="post" action="https://formspree.io/f/xvgpzjal">
              <input
                type="hidden"
                id="captchaResponse"
                name="g-recaptcha-response"
              ></input>

              <script
                dangerouslySetInnerHTML={{
                  __html: `
                  grecaptcha.ready(function() {
                    grecaptcha.execute('6Lf992AeAAAAAK-JN5F3GBhI3_hHy1kJ3sT4qVEp', {action: 'homepage'})
                    .then(function(token) {
                      document.getElementById('captchaResponse').value = token;
                    });
                  });
                  `,
                }}
              />

              <div className="form-group">
                <label for="fullName">Full Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="fullName"
                  name="fullName"
                />
              </div>
              <div className="form-group">
                <label for="businessName">Business Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="businessName"
                  name="businessName"
                />
              </div>
              <div className="form-group">
                <label for="contactNumber">Contact Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="contactNumber"
                  name="contactNumber"
                />
              </div>
              <div className="form-group">
                <label for="emailAddress">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="emailAddress"
                  name="emailAddress"
                />
              </div>
              <div className="form-group">
                <label for="service">Service</label>
                <select className="custom-select" id="service" name="service">
                  <option value="Sawing">Sawing</option>
                  <option value="Drilling">Drilling</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label for="message">Enquiry Details</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="7"
                  cols="50"
                ></textarea>
              </div>
              <div className="form-group">
                <StyledButton className="btn btn-primary" type="submit">
                  Send Enquiry
                </StyledButton>
              </div>
            </form>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <Googlemap
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.3693002139653!2d150.95758665145124!3d-33.776960321635926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b224811b90a3151%3A0x871390765417518d!2sOnline%20Concrete%20Cutting%20Services!5e0!3m2!1sen!2sau!4v1626621724231!5m2!1sen!2sau"
              loading="lazy"
            />
          </Col>
        </Row>
      </Pagelayout>
    </>
  );
}

export default ContactPage;

export const query = graphql`
  query {
    directus {
      contact {
        contact_details
        address
        email
        phone
        mailing
      }
      settings {
        site_title
        site_description
        site_author
        site_logo {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        footer_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        contact_details
      }
      home {
        seo_title
        hero_title
        hero_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        hero_subtitle
        about_heading
        about_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        accreditation
        professionalism
        projects_heading
        projects_summary
        training
        projects_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
